import { db } from "@/firebase";
import { ATTESTATIONS_COLLECTION } from "@/constants/collections";
import { collection, addDoc, getDocs } from "firebase/firestore";

const attestationsCollection = collection(db, ATTESTATIONS_COLLECTION);

export async function saveAttestationIdToDB(attestationId) {
  let response;
  let error;

  try {
    const docRef = await addDoc(attestationsCollection, {
      attestationId: attestationId,
      createdAt: new Date().toISOString(),
    });

    response = docRef.id;
  } catch (e) {
    console.error(e);
    error = e;
  }

  return { response, error };
}

export async function getAttestationsIDs() {
  let response = [];
  let error;

  try {
    const snapshot = await getDocs(attestationsCollection);
    snapshot.forEach((doc) => {
      response.push(doc.data().attestationId);
    });
  } catch (e) {
    console.error(e);
    error = e;
  }

  return { response, error };
}
