import { db } from "@/firebase";
import { REVIEWS_COLLECTION } from "@/constants/collections";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore";

const reviewsCollection = collection(db, REVIEWS_COLLECTION);

export const saveReviews = async (requestName, data) => {
  const q = query(
    reviewsCollection,
    where("requestName", "==", requestName),
    limit(1)
  );
  const snapshot = await getDocs(q);

  if (snapshot.empty) {
    console.log("process start ...")
    await addDoc(reviewsCollection, {
      ...data,
    });
  } else {
    console.log("review with request name exist, updating review info ...")
    const documentRef = doc(db, REVIEWS_COLLECTION, snapshot.docs[0].id);
    await updateDoc(documentRef, {
      ...data,
    });
  }

  console.log("process ends ...")
};

export async function getReviews(requestName) {
  let response;
  let error;
  try {
    const q = query(
      reviewsCollection,
      where("requestName", "==", requestName),
      limit(1)
    );
    const snapshot = await getDocs(q);
    if (!snapshot.empty) {
        response = snapshot.docs[0].data();
    } else {
        response = null;
    }
  } catch (e) {
    error = e;
  }

  return { response, error };
}

export async function getAllReviews() {
  let response = [];
  let error;
  try {
    const snapshot = await getDocs(reviewsCollection);
    snapshot.forEach((doc) => {
      response.push(doc.data());
    });
  } catch (e) {
    error = e;
  }

  return { response, error };
}

export async function getReviewByAttestationID(hypercertID, attestationID) {
  let response;
  let error;
  try {
    const snapshot = await getDocs(reviewsCollection);

    if (snapshot.empty) {
      return { response, error };
    } else {
      snapshot.forEach((doc) => {
        const review = doc.data();
        const matchingReview = review.reviews?.find((reviewItem) => {
          return (
            reviewItem.hypercertID === hypercertID &&
            reviewItem.attestationID === attestationID
          );
        });

        if (matchingReview) {
          response = {
            ...matchingReview,
            requestName: review.requestName,
            systemVersion: review.systemVersion,
          };
        }
      });
    }
  } catch (e) {
    error = e;
  }

  return { response, error };
}
