<template>
  <div class="app-container" v-loading="loading">
    <Layout v-if="!loading">
      <router-view />
    </Layout>
  </div>
</template>

<script>
import { ref, watch, computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import Layout from "./components/layout/index.vue";

import { createAppKit, useAppKitAccount, useAppKitNetwork } from '@reown/appkit/vue';
import { getWagmiAdapter, networks, projectId, metadata } from "./utils/config";
import { getEasSchemaIds } from "./services/ContractService";
import { NETWORK_IDS } from "@/constants/walletConstants";
// import { useReconnect } from "@wagmi/vue";


export default {
  name: "App",
  components: {
    Layout,
  },

  setup() {
    const store = useStore();
    const { dispatch } = store;

    dispatch("setLoading", true);

    let modalKit;
    const wagmiAdapter = getWagmiAdapter();
    const loading = ref(false);

    // const { reconnect } = useReconnect()

    onBeforeMount(async () => {
      try {
        modalKit = createAppKit({
          adapters: [wagmiAdapter],
          networks,
          metadata,
          themeMode: 'light',
          projectId,
          features: {
            email: false, // default to true
            socials: [],
            emailShowWallets: false, // default to true
          },
        });
        // reconnect()
      } catch (e) {
        dispatch("setLoading", false);
        dispatch("resetContractInformation");
        dispatch("resetWalletInformation");
        console.log(e);
      }
    })

    const accountInfo = useAppKitAccount().value;
    const network = useAppKitNetwork().value;

    const status = computed(() => accountInfo?.status);
    const connected = computed(() => accountInfo?.isConnected);
    const chainId = computed(() => network?.chainId);

    watch([status, connected], async ([newStatus, isConnected]) => {
      const config = modalKit?.chainAdapters['eip155'].wagmiConfig;
      console.log(status.value, newStatus);
      console.log(connected.value, isConnected)

      if (newStatus === "connected" && isConnected) {
        if (NETWORK_IDS[process.env.NODE_ENV].includes(chainId.value)) {
          const {
            reviewsSchemaID,
            amendmentsSchemaID
          } = await getEasSchemaIds(config);
          dispatch("setWalletInformation", { walletAddress: accountInfo?.address, networkId: chainId.value, balance: null });
          dispatch("setWagmiConfig", config);
          dispatch("setEasSchemaIDs", { reviewsSchemaID, amendmentsSchemaID });
          dispatch("setLoading", false);
        } else {
          dispatch("resetContractInformation");
          modalKit.disconnect();
        }
      }

      if (newStatus === "disconnected") {
        dispatch("resetContractInformation");
        dispatch("resetWalletInformation");
      }
    })

    watch(chainId, async (newChainId) => {
      const config = modalKit?.chainAdapters['eip155'].wagmiConfig;
      // console.log(newChainId, config?.state.chainId);

      if (accountInfo.isConnected) {
        if (NETWORK_IDS[process.env.NODE_ENV].includes(newChainId)) {
          const {
            reviewsSchemaID,
            amendmentsSchemaID
          } = await getEasSchemaIds(config);
          dispatch("setWalletInformation", { walletAddress: accountInfo?.address, networkId: newChainId, balance: null })
          dispatch("setWagmiConfig", config);
          dispatch("setEasSchemaIDs", { reviewsSchemaID, amendmentsSchemaID });
          dispatch("setLoading", false);
        } else {
          dispatch("resetContractInformation");
          modalKit.disconnect();
        }
      }
    })
    return { loading };
  },
};
</script>
<style lang="less">
.el-main {
  padding: 10px;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.app-container {
  height: 100vh;
}
</style>
