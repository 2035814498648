import { db } from "@/firebase";
import { AMENDMENTS_COLLECTION } from "@/constants/collections";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore";

const amendmentsCollection = collection(db, AMENDMENTS_COLLECTION);

export const saveAmendment = async (data) => {
  const q = query(
    amendmentsCollection,
    where("uid", "==", data.amendmentUID),
    limit(1)
  );
  const snapshot = await getDocs(q);

  if (snapshot.empty) {
    console.log("process start ...")
    await addDoc(amendmentsCollection, {
      ...data,
    });
  } else {
    console.log("ammendments with uid exist, updating form info ...")
    const documentRef = doc(db, AMENDMENTS_COLLECTION, snapshot.docs[0].id);
    await updateDoc(documentRef, {
      ...data,
    });
  }
  console.log("process ends ...")
};

export async function getReviewAmendments(tokenID) {
  let response = [];
  let error;
  try {
    const q = query(amendmentsCollection, where("hypercertID", "==", tokenID));
    const snapshot = await getDocs(q);

    snapshot.forEach((doc) => {
      response.push(doc.data());
    });

  } catch (e) {
    error = e;
  }

  return { response, error };
}

export async function getAmendmentsByRefUID(refUID) {
  let response = [];
  let error;
  try {
    const q = query(amendmentsCollection, where("refUID", "==", refUID));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
      response.push(doc.data());
    });
  } catch (e) {
    error = e;
  }

  return { response, error };
}
