import { DERESY_CONTRACT_OPTIMISM_ABI } from "../constants/contractConstants"
import celo_abi from "../utils/celo-abi.json"

export const getContractAddress = chainId => {
  switch(chainId) {
    case 11155111: return process.env.VUE_APP_SEPOLIA_CONTRACT_ADDRESS
    case 10: return process.env.VUE_APP_OPTIMISM_CONTRACT_ADDRESS
    case 44787: return process.env.VUE_APP_ALFAJORES_CONTRACT_ADDRESS
    case 42220: return process.env.VUE_APP_CELO_CONTRACT_ADDRESS
    default: return "not_supported"
  }
}

export const getContractInfo = chainId => {
  switch(chainId) {
    case 11155111: return {
      address: process.env.VUE_APP_SEPOLIA_CONTRACT_ADDRESS,
      abi: DERESY_CONTRACT_OPTIMISM_ABI
    }
    case 10: return {
      address: process.env.VUE_APP_OPTIMISM_CONTRACT_ADDRESS,
      abi: DERESY_CONTRACT_OPTIMISM_ABI
    }
    case 44787: return {
      address: process.env.VUE_APP_ALFAJORES_CONTRACT_ADDRESS,
      abi: celo_abi
    }
    case 42220: return {
      address: process.env.VUE_APP_CELO_CONTRACT_ADDRESS,
      abi: celo_abi
    }
    default: return "not_supported"
  }
}
